<template>
    <main>
        <HeaderTab :title="$t('mediatheque.title')" :swiper_tabs="swiper_tabs"  />
        <router-view></router-view>
    </main>
</template>


<script type="text/javascript">
	import Notes from "@/mixins/Notes.js"
    export default {
        mixins: [
            Notes
        ],
        name: "Mediatheque",
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
        },
        data () {
            return {
                has_notes: false
			}
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component(){
                const end = new Date()
                const start = new Date(new Date().setMonth(new Date().getMonth() - 3))
                const notes = await this.getNotes(start.toDateInputValue(), end.toDateInputValue())
                this.has_notes = notes.length > 0
            },
        },
        computed: {
            swiper_tabs() {
                return [{
                        label: 'mediatheque.registres_history',
                        href : 'registryHistory',
                        active: false
                    }, {
                        label: 'global.ordonnances',
                        href : 'ordonnanceListe',
                        active: false
                    }, 
                    {
                        label: this.has_notes ? 'global.with_notes' : 'global.notes',
                        href : 'noteListe',
                        hidden: !this.hasNoteAccess,
                        active: false
                    }
                ]
            },
            hasNoteAccess() {
                return this.$store.state.userAccess.hasNoteAccess
            }
        }
    }
</script>
